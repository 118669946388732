import axios from 'axios';

const API_URL = '/claude';

export const getWineRecommendation = async (userInput) => {
  try {
    console.log('Sending request to server...');
    const response = await axios.post(API_URL, { userInput }, {
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 30000
    });

    console.log('Received response from server:', response.data);
    return response.data.content[0].text;
  } catch (error) {
    console.error('Error getting recommendation:', error);
    return 'Sorry, there was an error getting a recommendation. Please try again.';
  }
};
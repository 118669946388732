import React, { useEffect } from 'react';
import { Box } from '@mui/material';

const AdSenseAd = ({ slot, format = 'auto', responsive = true }) => {
  useEffect(() => {
    // Load AdSense script if not already loaded
    if (!window.adsbygoogle) {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      script.crossOrigin = "anonymous";  // Add crossOrigin attribute
      script.async = true;
      document.head.appendChild(script);
    }
    try {
      // Delay the push to ensure container is rendered
      const timer = setTimeout(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }, 300);

      return () => clearTimeout(timer);
    } catch (error) {
      console.error('AdSense error:', error);
    }
  }, []);

  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        my: 3,
        mx: 'auto',
        width: '100%',  // Ensure container has width
        minHeight: '250px',  // Increase minimum height
        maxWidth: '100%',
        overflow: 'hidden',
        bgcolor: 'transparent'
      }}
    >
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          width: '100%',  // Set explicit width
          minHeight: '250px',  // Set minimum height
          backgroundColor: 'transparent',
          margin: '0 auto',
        }}
        data-ad-client="ca-pub-4142317577296431"
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive={responsive}
        data-adtest="on"  // Add this during testing
      />
    </Box>
  );
};

export default AdSenseAd;
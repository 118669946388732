export const sortedUniqueFoodItems = [
  "Abbacchio alla Romana", "Acqua Pazza", "Agedashi Tofu", "Agneau à la Provençale", 
  "Agnello al Forno", "Ahi Tuna Steak", "Alaskan King Crab", "Arrosticini", "Avocado Toast", "BBQ Chicken Pizza", 
  "BBQ Ribs", "Baccalà alla Vicentina", "Baklava", "Banana Fritters", 
  "Beef Bourguignon", "Beef Carpaccio", "Beef Stew", "Beef Stroganoff", 
  "Beef with Broccoli", "Bhaji", "Bibimbap", "Bibimbap (비빔밥)", "Bingsu", 
  "Biryani", "Biscuits", "Bistecca alla Fiorentina", "Black Sesame Pudding", "Blackened Redfish", 
  "Boeuf Bourguignon", "Bouillabaisse", "Brandade de Morue", "Brasato al Barolo", 
  "Breakfast Burrito", "Brisket", "Brioche", "Bruschetta", "Bulgogi", 
  "Bulgogi (불고기)", "Burger", "Burritos", "Butter Chicken", "Cacciucco", 
  "Caesar Salad", "Calamari", "Camembert", "Canard à l'Orange", "Cannoli", 
  "Caprese Salad", "Carbonara", "Cassoulet", "Ceviche", "Char Siu (BBQ Pork) (叉烧)", 
  "Chateaubriand", "Cheese Soufflé", "Cheeseburger", "Cheesecake", 
  "Chicken Cacciatore", "Chicken Korma", "Chicken Parmesan", "Chicken Piccata", 
  "Chicken Pot Pie", "Chicken Quesadilla", "Chicken Satay", "Chicken Tenders", 
  "Chicken Wings", "Chilean Sea Bass", "Chili", "Choucroute Garnie", "Chow Mein", "Cobb Salad", "Coconut Custard", "Coconut Shrimp", "Coffee", "Coleslaw", "Coq au Vin", 
  "Corn Dog", "Cotoletta alla Milanese", "Couscous", "Crab Cakes", "Crab Legs", "Creamed Spinach", "Crepes", 
  "Crispy Spring Rolls (炸春卷)", "Croissant", "Croissant Sandwich", "Crème Brûlée", 
  "Cumin Lamb", "Curry", "Dim Sum", "Dim Sum (Assorted) (点心)", "Dolmades", 
  "Dongpo Pork", "Duck Confit", "Duck à l'Orange", "Dumplings", "Eclairs", 
  "Egg Roll", "Egg Salad", "Eggplant Parmesan", "Eggs Benedict", "Enchiladas", 
  "Entrecôte Café de Paris", "Escargot", "Fajitas", "Falafel", "Falafel Wrap", 
  "Fettuccine Alfredo", "Filet Mignon", "Fish Tacos", "Fish and Chips", "Flan", 
  "French Fries", "French Onion Soup", "French Toast", "Fried Calamari", "Fried Chicken", 
  "Fried Fish with Chili Sauce (ปลาทอดราดพริก)", "Fried Ice Cream", 
  "Fried Rice", "Galaktoboureko", "Galette", "Garlic Bread", "Garlic Mashed Potatoes", "Gelato", 
  "General Tso's Chicken", "Gigot d'Agneau", "Gnocchi", "Granola", 
  "Gratin Dauphinois", "Greek Salad", "Green Curry (แกงเขียวหวาน)", 
  "Green Tea Ice Cream", "Grilled Cheese", "Grilled Chicken", "Grilled Halibut", "Grilled Octopus", 
  "Grilled Pork Neck (คอหมูย่าง)", "Grilled Salmon", "Guacamole", 
  "Gulab Jamun", "Gyoza", "Gyro", "Haemul Pajeon (Seafood Pancake) (해물파전)", 
  "Hainanese Chicken Rice", "Hash Browns", "Hoddeok (Sweet Pancake) (호떡)", 
  "Hot Dog", "Hot Pot", "Hot Pot (火锅)", "Hummus", "Ice Cream", 
  "Jambon-Beurre", "Japchae (Stir-fried Glass Noodles) (잡채)", "Jjajangmyeon (Black Bean Noodles) (짜장면)", 
  "Kale Salad", "Kimchi Jjigae (Kimchi Stew) (김치찌개)", "Kulfi", 
  "Kung Pao Chicken", "Kung Pao Chicken (宫保鸡丁)", "Lamb Chops", 
  "Lamb Curry", "Lamb Hot Pot", "Lamb Kleftiko", "Lamb Stew", "Lasagna", 
  "Lo Mein", "Lobster Bisque", "Lobster Roll", "Lobster Tail", "Mac and Cheese", "Mac and Cheese with Truffle Oil", "Macarons", 
  "Madeleine", "Mango Sticky Rice", "Mango Sticky Rice (ข้าวเหนียวมะม่วง)", 
  "Mapo Tofu", "Mapo Tofu (麻婆豆腐)", "Margherita Pizza", "Massaman Curry (แกงมัสมั่น)", 
  "Meat Lovers Pizza", "Meatballs", "Meatloaf", "Mee Goreng", "Milkshake", 
  "Mille-feuille", "Miso Soup", "Mochi", "Mongolian Beef", 
  "Moules Marinières", "Moussaka", "Mozzarella Sticks", "Muffin", 
  "Mussels Marinara", "Naan", "Nachos", "Navarin d'Agneau", "New York Strip Steak", "Nigiri Sushi", 
  "Nicoise Salad", "Oeufs en Meurette", "Okonomiyaki (Savory Pancake) (お好み焼き)", 
  "Omelette", "Onion Rings", "Orange Chicken", "Osso Buco", "Oysters", "Oysters Rockefeller", 
  "Pad See Ew", "Pad Thai", "Pad Thai (ผัดไทย)", "Pakora", "Panang Curry", 
  "Panang Curry (พะแนง)", "Pancakes", "Panna Cotta", "Parmigiana di Melanzane", 
  "Pasta", "Pastitsio", "Pâté", "Pâté en Croûte", "Peking Duck", 
  "Peking Duck (北京烤鸭)", "Pepperoni Pizza", "Philly Cheesesteak", "Pho", 
  "Pineapple Tart", "Pizza", "Poached Eggs", "Pollo al Mattone", 
  "Pollo alla Cacciatora", "Porchetta", "Pork Chops",  "Porterhouse Steak", "Pot-au-Feu", 
  "Potato Salad", "Potstickers", "Prime Rib", "Profiteroles", "Prosciutto di Parma", 
  "Pulled Pork", "Quesadillas", "Quiche Lorraine", "Quinoa Salad", "Ramen", 
  "Ramen (Rich Broth Noodles) (ラーメン)", "Ratatouille", "Ravioli", "Ribeye Steak", "Risotto", 
  "Rogan Josh", "Roasted Duck Breast", "Saganaki", "Salad", "Salmon en Papillote", "Salmon Tartare", 
  "Salmon Terrine", "Salsa", "Samosa", "Sashimi", "Sashimi (刺身)", "Scallops", 
  "Scrambled Eggs", "Seared Scallops", "Sesame Chicken", "Shepherd's Pie", 
  "Shabu Shabu (Hot Pot) (しゃぶしゃぶ)", "Shish Kebabs", "Shrimp Cocktail", 
  "Shrimp Saganaki", "Shrimp Scampi", "Shrimp Tempura", "Sichuan Boiled Fish", 
  "Sichuan Spicy Chicken", "Singapore Noodles", "Sliders", "Smoothie Bowl", 
  "Soda", "Sole Meunière", "Som Tum (Papaya Salad) (ส้มตำ)", "Soup", 
  "Sour Cream", "Souris d'Agneau", "Spaghetti", "Spanakopita", 
  "Spicy Lamb Skewers", "Spring Rolls", "Steak", "Steak Frites", 
  "Steak Tartare", "Steamed Fish with Ginger and Scallions", 
  "Steamed Fish with Ginger and Scallions (清蒸鱼)", "Steamed Mussels", "Sticky Rice with Mango", 
  "Stir-fried Beef with Broccoli (西兰花炒牛肉)", "Stuffed Grape Leaves", 
  "Stuffed Peppers", "Sukiyaki", "Surf and Turf (Steak and Lobster)", "Sushi", "Sushi (Assorted) (寿司)", 
  "Sweet and Sour Pork", "Sweet and Sour Pork (糖醋里脊)", "Swordfish Steak", "T-Bone Steak", "Tacos", 
  "Takoyaki", "Tamales", "Tandoori Chicken", "Tarte Tatin", "Tartelette", 
  "Tea", "Tempura", "Tempura (天ぷら)", "Teriyaki Chicken", 
  "Thai Basil Stir-fry (ผัดกะเพรา)", "Thai Green Curry", "Thai Red Curry", 
  "Tikka Masala", "Tiramisu", "Toast", "Tom Kha Soup", 
  "Tom Yum Goong (Spicy Shrimp Soup) (ต้มยำกุ้ง)", "Tom Yum Soup", 
  "Tonkatsu", "Tonkatsu (Pork Cutlet) (豚カツ)", "Tortellini", 
  "Truffle Risotto", "Trout Amandine", "Tuna Salad", "Tuna Tartare", 
  "Twice-Cooked Pork", "Udon Noodles", "Unagi (Grilled Eel) (うなぎ)", 
  "Unagi Don", "Veal Marsala", "Veggie Burger", "Vindaloo", "Vitello Tonnato", 
  "Vol-au-vent", "Waffles", "Wagyu Beef (和牛)", 
  "West Lake Fish in Vinegar Gravy", "Yakisoba", "Yakitori (Grilled Chicken Skewers) (焼き鳥)", 
  "Yogurt Parfait"
];

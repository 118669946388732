import React, { useState } from 'react';
import { 
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Snackbar,
} from '@mui/material';
import {
  Facebook,
  Twitter,
  WhatsApp,
  ContentCopy,
  Share as ShareIcon,
  Email,
  LinkedIn
} from '@mui/icons-material';

const AppShareButtons = () => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const appUrl = window.location.origin;
  const appName = "AI Wine Pairing";
  const shareText = "Discover the perfect wine pairing for any dish with this AI-powered wine recommendation app!";
  
  const getShareData = () => ({
    url: appUrl,
    text: encodeURIComponent(shareText),
    title: encodeURIComponent(appName)
  });

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(appUrl);
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const actions = [
    { 
      icon: <Facebook />, 
      name: 'Share on Facebook', 
      onClick: () => {
        const { url, text } = getShareData();
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`,
          '_blank'
        );
      }
    },
    { 
      icon: <Twitter />, 
      name: 'Share on Twitter', 
      onClick: () => {
        const { url, text } = getShareData();
        window.open(
          `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
          '_blank'
        );
      }
    },
    { 
      icon: <LinkedIn />, 
      name: 'Share on LinkedIn', 
      onClick: () => {
        const { url, title, text } = getShareData();
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}&summary=${text}`,
          '_blank'
        );
      }
    },
    { 
      icon: <WhatsApp />, 
      name: 'Share on WhatsApp', 
      onClick: () => {
        const { url, text } = getShareData();
        window.open(
          `https://wa.me/?text=${text} ${url}`,
          '_blank'
        );
      }
    },
    { 
      icon: <Email />, 
      name: 'Share via Email', 
      onClick: () => {
        const { url, title, text } = getShareData();
        window.open(
          `mailto:?subject=${title}&body=${text}%0A%0A${url}`,
          '_blank'
        );
      }
    },
    { 
      icon: <ContentCopy />, 
      name: 'Copy Link', 
      onClick: handleCopyLink 
    },
  ];

  return (
    <>
      <SpeedDial
        ariaLabel="Share App"
        sx={{ 
          position: 'fixed', 
          bottom: 100, 
          right: 16,
          zindex: 1000,
          '& .MuiSpeedDial-fab': {
            bgcolor: 'primary.main',
            '&:hover': {
              bgcolor: 'primary.dark',
            }
          }
        }}
        icon={<SpeedDialIcon />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => {
              action.onClick();
              setOpen(false);
            }}
            sx={{
              '&.MuiSpeedDialAction-fab': {
                bgcolor: 'white',
                '&:hover': {
                  bgcolor: 'grey.100',
                }
              }
            }}
          />
        ))}
      </SpeedDial>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="App link copied to clipboard!"
      />
    </>
  );
};

export default AppShareButtons;
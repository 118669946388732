// Updated Footer.js
import React from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import AppShareButtons from './AppShareButtons';

const Footer = () => {
  return (
    <Box sx={{ 
      bgcolor: 'rgba(0, 0, 0, 0.8)', 
      color: 'white',
      py: 3,
      mt: 'auto' 
    }}>
      <Container maxWidth="lg">
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2
        }}>
          <Box>
            <Typography variant="h6" gutterBottom>
              AI Wine Match
            </Typography>
            <Typography variant="body2">
              © {new Date().getFullYear()} AI Wine Match. All rights reserved.
            </Typography>
          </Box>
          
          <Box sx={{ 
            display: 'flex', 
            gap: 3,
            flexWrap: 'wrap',
            justifyContent: 'center' 
          }}>
            <Link href="/about" color="inherit" underline="hover">
              About
            </Link>
            <Link href="/privacy" color="inherit" underline="hover">
              Privacy Policy
            </Link>
            <Link href="/terms" color="inherit" underline="hover">
              Terms of Service
            </Link>
            <Link href="/contact" color="inherit" underline="hover">
              Contact
            </Link>

          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
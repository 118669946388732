import React, { useState, useEffect } from 'react';
import { 
  Snackbar, 
  Button, 
  Box 
} from '@mui/material';

const CookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message="This website uses cookies to enhance your experience and show relevant ads."
      action={
        <Box>
          <Button 
            color="secondary" 
            size="small" 
            onClick={handleAccept}
          >
            Accept
          </Button>
          <Button
            color="secondary"
            size="small"
            href="/privacy"
          >
            Learn More
          </Button>
        </Box>
      }
    />
  );
};

export default CookieConsent;